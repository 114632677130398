<template>
  <div class="container">
    <!-- 账户冻结弹窗 -->
    <!-- <div v-show="logined" v-if="blocked == 1 && blockedDialog" class="blockedDialog" @click="closeDialog">
      <div class="blocked-content">
        <div style="font-size: 1rem;">
          你的账户已被冻结，请联系管理员解冻
        </div>
      </div>
    </div>-->
    <div v-show="logined">
      <!-- 首页 -->
      <div>
        <!-- 底部导航栏 -->
        <van-tabbar v-if="blocked != 1" v-model="active" @click="clickBtn()">
          <van-tabbar-item replace to="/">
            <template #icon="props">
              <img :src="props.active ? homeActive : home" />
            </template>
          </van-tabbar-item>
          <van-tabbar-item replace to="/center">
            <template #icon="props">
              <img
                :src="props.active ? mineActive : mine"
                style="width: 30px; height: 30px"
              />
            </template>
          </van-tabbar-item>
        </van-tabbar>

        <!-- <swipe
          v-model="index"
          :autoplay-time="2000"
          style="text-align: center; line-height: 80px; height: 150px"
        >
          <swipe-item v-for="banner of banners" :key="banner" style="height: 100px; line-height: 100px">
            <img :src="banner" alt="" style="width: 100%">
          </swipe-item>
        </swipe>-->
        <!-- 首页主界面 -->
        <div style="padding-bottom: 3rem">
          <div class="search">
            <div class="search_top">
              <form action @submit.prevent>
                <van-search
                  v-model="value"
                  type="search"
                  background="none"
                  shape="round"
                  @keypress="onSearch($event)"
                />
              </form>
              <van-popover
                v-if="actions.length !== 0"
                v-model="showPopover"
                trigger="click"
                :actions="actions"
                placement="bottom-end"
                @select="onSelect"
              >
                <template #reference>
                  <van-icon name="wap-nav" />
                </template>
              </van-popover>
            </div>
            <div class="banner_top">
              <van-swipe autoplay="2500" indicator-color="transparent">
                <van-swipe-item v-for="(item, index) in banners" :key="index">
                  <img :src="item.url" alt @click="toIcbcPage(item.path)" />
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>

          <div v-show="tip === false" style="position: relative; height: 100%">
            <!-- 智慧食堂 -->
            <!-- <div v-if="functionalServices.includes(1)" class="category">
              <div class="title">智慧食堂</div>
              <div class="content-top">
                <div>
                  <div
                    style="display:flex;flex-direction: row;justify-content: space-between;margin-left:-15px"
                  >
                    <div class="balance-right" style="width:130px;">
                      <div style="color: #515151; font-size: 0.95rem">卡内余额</div>
                      <div>
                        <count-to
                          v-if="balance || balance === 0"
                          :start-val="0"
                          :end-val="balance"
                          :duration="1000"
                          :decimals="2"
                          separator=","
                          prefix="¥"
                          suffix
                          class="balance"
                        />
                      </div>
                    </div>
                    <div style="width:80px;margin-left: 10px;text-align: center;">
                      <div style="color: #515151; font-size: 0.95rem">优惠余额</div>
                      <div>
                        <count-to
                          v-if="coupon || coupon === 0"
                          :start-val="0"
                          :end-val="coupon"
                          :duration="1000"
                          :decimals="2"
                          separator=","
                          prefix="¥"
                          suffix
                          class="coupon"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="normal_btn" @click="toPage('RechargeCenter')">充值</div>
                </div>
                <div style="display: flex; flex-direction: column; align-items: center">
                  <div style="color: #515151; font-size: 0.95rem">付款码</div>
                  <img :src="payCode" alt style="width: 4.1rem" @click="toPage('Paycode')" />
                </div>
              </div>
              <div class="content-bottom">
                <div class="items">
                  <div
                    v-for="item in canteen"
                    :key="item.img"
                    class="detail"
                    @click="toPage(item.func)"
                  >
                    <div class="item-flex">
                      <img :src="item.img" alt />
                      <div>{{ item.title }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>-->
            <!-- 智慧门禁 -->
            <!-- <div v-if="functionalServices.includes(2)" class="category">
              <div class="title">智慧门禁</div>
              <div class="content-bottom" style="background-color: #fff">
                <div class="items">
                  <div
                    v-for="item in accesses"
                    :key="item.title"
                    class="detail"
                    @click="toPage(item.func)"
                  >
                    <img :src="item.img" alt />
                    <div>{{ item.title }}</div>
                  </div>
                  <div class="detail" @click="toPage('SmsAuth')">
                    <img :src="smsAuth" />
                    <div>通知设置</div>
                  </div>
                </div>
              </div>
            </div>-->
            <!-- 智慧缴费 -->
            <!-- <div v-if="functionalServices.includes(3)" class="category">
              <div class="title">智慧缴费</div>
              <div class="content-bottom" style="background-color: #fff">
                <div class="items">
                  <div class="detail" @click="payFor">
                    <img :src="tuition" />
                    <div>学杂费</div>
                  </div>
                </div>
              </div>
            </div>-->

            <div
              v-show="tip === false"
              style="position: relative; height: 100%"
            >
              <div class="content">
                <div class="content-bottom">
                  <div class="items">
                    <div
                      v-for="item in homeApp.config"
                      :key="item.img"
                      class="detail"
                      @click="toPage(item.func)"
                    >
                      <div class="item-flex">
                        <img :src="item.img" alt />
                        <div>{{ item.title }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="infrom">
                <div class="infrom_left">
                  <div class="infrom_left_img">
                    <img :src="infrom2" alt />
                  </div>
                  <span style="font-size: 0.9rem; color: black">通知</span>
                </div>
                <van-swipe
                  v-if="!skipDisable && informList.length !== 0"
                  class="infrom_swipe"
                  autoplay="2000"
                  indicator-color="transparent"
                  vertical
                >
                  <van-swipe-item v-for="item in informList" :key="item.id">
                    {{ item.title }}
                  </van-swipe-item>
                </van-swipe>
                <van-swipe
                  v-if="skipDisable || informList.length === 0"
                  class="infrom_swipe"
                  autoplay="2000"
                  indicator-color="transparent"
                  vertical
                >
                  <!-- <van-swipe-item style="font-size:0.9rem" v-if="h5Title==='智慧校园'">欢迎使用智慧校园系统~~~</van-swipe-item>
                  <van-swipe-item style="font-size:0.9rem" v-else>欢迎使用智慧政企系统~~~</van-swipe-item>-->
                  <van-swipe-item style="font-size: 0.9rem">
                    欢迎使用本系统~~~
                  </van-swipe-item>
                </van-swipe>
              </div>
              <div class="swipe_bottom">
                <img :src="fullLoad" alt />
                <p>
                  <span>一</span>
                  已全部加载
                  <span>一</span>
                </p>
              </div>
            </div>
            <div v-show="tip === true" class="dialog_content">
              <h3>提示</h3>
              <div class="dialog_tip">用户信息解析失败</div>
              <h3 class="confirm">确定</h3>
            </div>
            <van-overlay :show="loading" class="init">
              <van-loading color="#1989fa" />
            </van-overlay>
          </div>
          <!-- 充值弹窗 -->
          <div
            v-if="showDialog && balance < 0 && blocked != 1"
            class="dialogShow"
            @click="hideDialog"
          >
            <el-dialog style="text-align: center" :visible.sync="centerDialog">
              <div style="margin-bottom: 1rem; font-size: 1rem">
                卡内余额不足，请充值
              </div>
              <el-button style="width: 80%" type="danger" @click="hideDialog">
                关闭
              </el-button>
            </el-dialog>
          </div>

          <div v-show="tip === true" class="dialog_content">
            <h3>提示</h3>
            <div class="dialog_tip">用户信息解析失败</div>
            <h3 class="confirm">确定</h3>
          </div>
          <van-overlay :show="loading" class="init">
            <van-loading color="#1989fa" />
          </van-overlay>
        </div>

        <!-- 冻结账户弹窗 -->
        <div v-if="blocked == 1 && blockedDialog" class="cover">
          <div class="cover-content">
            <!-- <div style="font-size: 1.2rem;">
              你的账户已被冻结，请联系管理员解冻
            </div>-->
            <div style="font-size: 1.3rem">您的账户已被冻结!</div>
            <div style="font-size: 1.3rem">请联系管理员解冻!</div>
          </div>
        </div>
      </div>

      <!-- 非注册用户 -->
      <van-overlay :show="!identification" class="init">
        <div class="function-open">
          <div>非注册用户</div>
          <div>没有浏览权限</div>
        </div>
      </van-overlay>
      <!-- 请选择手机号 -->
      <van-action-sheet
        v-model="phoneShow"
        :actions="phones"
        description="请选择手机号"
        :close-on-click-overlay="false"
        @select="handlePhoneSelect"
      />
      <!-- 请选择商户 -->
      <van-action-sheet
        v-model="orgShow"
        :actions="orgs"
        description="请选择商户"
        :close-on-click-overlay="false"
        @select="handleOrgSelect"
      />
      <!-- 请选择用户 -->
      <van-action-sheet
        v-model="userShow"
        :actions="users"
        description="请选择用户"
        :close-on-click-overlay="false"
        @select="handleUserSelect"
      />
    </div>
    <div v-show="!logined" class="container1">
      <div class="nav">
        <div class="nav_title">温馨提示</div>
      </div>
      <div class="content">
        <div>
          <img
            :src="emptyImg"
            alt
            width="220"
            height="200"
            class="finish-img"
          />
        </div>
        <div class="finish-text block1">您绑定的手机号是：{{ phone }}</div>
        <div class="finish-text block1">该账号未开通此功能</div>
        <el-button type="danger" @click="unboundBtn()">点击解除绑定</el-button>

        <div class="footer">客服电话：028-60656777</div>

        <div v-if="dialog === true" class="dialog" @click="hideDialogBtn">
          <el-dialog style="text-align: center" :visible.sync="centerDialog">
            <div style="margin-bottom: 0.6rem; font-size: 1rem">
              解除绑定成功！
            </div>
            <div style="margin-bottom: 1rem; font-size: 1rem">
              请退出App，重新进入。
            </div>
            <el-button style="width: 80%" type="danger" @click="hideDialogBtn">
              知道了
            </el-button>
          </el-dialog>
        </div>
      </div>
    </div>
    <van-dialog
      v-model="isDialog"
      class="dialog_box"
      confirm-button-text="退出"
      title="通知"
      :show-confirm-button="false"
      confirm-button-color="rgba(2,167,240)"
      @confirm="toPage('Login')"
    >
      <div class="dialog_div">系统升级中,请{{ uploadTime }}分钟后再尝试...</div>
    </van-dialog>
  </div>
</template>

<script>
import { isArray, isEmpty } from 'lodash';
import { Swipe, SwipeItem } from 'c-swipe';
import { round } from 'lodash';
import { Toast } from 'vant';
import countTo from 'vue-count-to';
import 'c-swipe/dist/swipe.css';
import userImg from '@/assets/user.png';
import mobile from '@/assets/mobile.png';
import payCode from '@/assets/pay_code.jpg';
import mine from '@/assets/mine.png';
import home from '@/assets/home.png';
import mineActive from '@/assets/mine_active.png';
import homeActive from '@/assets/home_active.png';
import consumption from '@/assets/consumption.png';
import eat from '@/assets/eat.png';
import recognize from '@/assets/recognize.png';
import recoginzePhoto from '@/assets/recognize_photo.png';
import history from '@/assets/history.png';
import allowance from '@/assets/allowance.png';
import checkWork from '@/assets/check-work.jpg';
import access from '@/assets/access.png';
import tuition from '@/assets/tuition.png';
import smsAuth from '@/assets/notice.png';
import leave from '@/assets/leave.png';
import examineLeave from '@/assets/examineLeave.png';
import banner3 from '@/assets/banner3.png';
import icbc_banner from '@/assets/icbc_banner.jpg';
import emptyImg from '@/assets/empty.png';
import approval_t from '@/assets/approval_t.png';
import icon_infrom from '@/assets/icon-infrom.png';
import infrom2 from '@/assets/infrom2.png';
import fullLoad from '@/assets/fullLoad.png';
import allowance2 from '@/assets/allowance2.png';
import eat2 from '@/assets/eat2.png';
import detail from '@/assets/detail.png';
import recharge_history from '@/assets/recharge_history.png';
import paycode from '@/assets/code.png';
import recharge from '@/assets/recharge (2).png';
import check from '@/assets/check.png';
import inform from '@/assets/inform.png';
import Leave from '@/assets/Leave2.png';
import all from '@/assets/all.png';
import leave3 from '@/assets/leave3.jpg';
import faceReview from '@/assets/face-review.png';
import u286 from '@/assets/u286.png';
import u287 from '@/assets/u287.png';
import reportMeal from '@/assets/my-report-meal.png';
import { getReportMealConfig } from '@/api/report-meal';

import {
  onloadImage,
  updateEmployeePhoto,
  queryBanners,
  getOrgByMobile,
  getUserList,
  getUserConfigAPI,
  weixinGetTokenAPI,
  getSystemSettingAPI
} from '@/api/api';
import { ICBCUtil } from '../../utils/icbc_core';
const { VUE_APP_BASE_API } = process.env;
import { getAccInfo, getUnbound } from '@/api/person';

export default {
  name: 'Index',
  components: { countTo, Swipe, SwipeItem },
  data() {
    return {
      fullLoad,
      infrom2,
      logined: true,
      identification: true,
      functionalServices: [],
      active: 0,
      emptyImg,
      userImg,
      mobile,
      payCode,
      mine,
      icon_infrom,
      home,
      mineActive,
      homeActive,
      leave,
      index: 0,
      index1: 0,
      index2: 0,
      fileList: [],
      banners: [],

      swiperOption: {
        speed: 800,
        loop: true,
        autoplay: {
          delay: 1500,
          disableOnInteraction: false
        }
      },
      imgs: {
        recognize,
        recoginzePhoto
      },
      mines: [{ img: recognize, title: '人脸绑定', func: '' }],
      canteen: [
        { img: history, title: '账单', func: 'RechargeHistory' },
        { img: consumption, title: '消费明细', func: 'Consumption' },
        { img: allowance, title: '补助金', func: 'Allowance' },
        { img: eat, title: '我的订餐', func: 'Order' }
        // { img: icon_infrom, title: "消息通知", func: "InformList" },
      ],
      loading: false,
      apiURL: process.env.VUE_APP_BASE_API,
      loadImg: false,
      card: '',
      fileImg: '',
      tip: false,
      user: {},
      userId: '',
      tuition,
      smsAuth,
      examineLeave,
      phoneShow: false,
      phone: '',
      organizationId: '',
      phones: [],
      orgShow: false,
      orgs: [],
      userShow: false,
      users: [],
      userID: '',
      tuitionPayUrl: `${VUE_APP_BASE_API}/icbc/tuition/pay`,
      infoData: null,
      picUrl: null,
      custID: null,
      showDialog: false,
      centerDialog: true,
      dialog: false,
      blocked: null,
      blockedDialog: false,
      value: '',
      homeApp: {
        config: []
      },
      appLocationList: [],
      // 通过 actions 属性来定义菜单选项
      actions: [],
      skipDisable: true,
      h5Title: '',
      showPopover: false,
      titleList: [],
      pathList: [],
      isDialog: false,
      uploadTime: '',
      showBannerList: [],
      newBanners: [
        {
          img: banner3,
          url: ''
        },
        {
          img: icbc_banner,
          url: 'https://imhdfs.icbc.com.cn/userfiles/public/static/87e323730d224a5dbbf902f0dfea7962.html?isWachat=1'
        }
      ],
      redirectUrl: process.env.VUE_APP_BASE_API1
    };
  },

  computed: {
    balance() {
      return round(this.user.balance / 100, 2);
    },
    coupon() {
      return round(this.user.coupon / 100, 2);
    },
    accesses() {
      const { user } = this;
      const {
        role,
        departmentManager,
        classManager,
        isClassManager,
        isManager
      } = user;
      const data = [
        { img: access, title: '出入记录', func: 'AccessRecord' },
        { img: leave, title: '考勤管理', func: 'CheckWork' }
      ];
      if (role === 1) {
        if (classManager) {
          data.push({ img: examineLeave, title: '请假', func: 'Leave' });
          // data.push({ img: examineLeave, title: '人脸', func: 'FaceCheckList' })
        }
      } else {
        if (departmentManager) {
          data.push({ img: examineLeave, title: '请假', func: 'Leave' });
          // data.push({ img: examineLeave, title: '人脸', func: 'FaceCheckList' })
        }
      }
      if (isClassManager || isManager) {
        data.push({
          img: checkWork,
          leave,
          title: '请假审批',
          func: 'ExamineLeave'
        });
      }
      if (isClassManager || isManager) {
        data.push({
          img: approval_t,
          leave,
          title: '人脸审批',
          func: 'FaceCheck'
        });
      }
      return data;
    },
    isAndroid() {
      return navigator.userAgent.indexOf('Android') > -1;
    }
  },
  async created() {
    document.title = '\u200E';
    // const url = window.location.href;
    // if (url.includes("iorg")) {
    //   document.title = "智慧政企";
    // } else {
    //   document.title = "智慧校园";
    // }
    // this.h5Title = document.getElementsByTagName("title")[0].innerText;
    const { token } = this.$store.getters;
    const { queryEmployee, fetchBanners, getOrg, afterLogin } = this;
    // const { phone, custID } = this.$route.query
    const phone = this.$route.query.phone
      ? this.$route.query.phone
      : '' || JSON.parse(sessionStorage.getItem('personInfo'))
      ? JSON.parse(sessionStorage.getItem('personInfo')).phone
      : '' || '';
    const custID = this.$route.query.custID
      ? this.$route.query.custID
      : '' ||
        window.location.href.split('custID=')[1] ||
        sessionStorage.getItem('custID')
      ? sessionStorage.getItem('custID')
      : '' || '';
    const xml = window.location.href.split('xml=')[1];
    const key = window.location.href.split('key=')[1];
    const zhonghezhihui = this.$route.query.zhonghezhihui
      ? this.$route.query.zhonghezhihui
      : '';
    const weixinPayment = this.$route.query.weixinPayment
      ? this.$route.query.weixinPayment
      : '';
    // 微信登录
    const weixin =
      this.$route.query.weixin && this.$route.query.weixin === '2023'
        ? this.$route.query.weixin
        : '';
    // 储存是否是微信登录
    sessionStorage.setItem('weixin', weixin);
    const code = this.$route.query.code;
    const appId =
      this.$route.query.appId || localStorage.getItem('appId') || '';

    if (appId) {
      localStorage.setItem('appId', appId);
    }

    if (custID) {
      sessionStorage.setItem('custID', custID);
    }
    if (!token) {
      if (phone && custID) {
        if (phone.indexOf(',') > -1) {
          const phones = phone.split(',');
          // const custID = window.location.href.split('custID=')[1]
          // console.log('custID', this.$route.query.custID)
          // console.log('custID2', custID)
          // sessionStorage.setItem('custID', JSON.stringify(this.$route.query.custID))
          sessionStorage.setItem('custID', custID);
          this.phoneShow = true;
          this.phones = phones.map((value) => {
            return { name: value };
          });
        } else {
          this.phone = phone;
          getOrg({ phone });
        }
      } else if (custID) {
        console.log('已存在custID进入登录页面', custID);
        // sessionStorage.setItem('custID', JSON.stringify(this.$route.query.custID))
        // sessionStorage.setItem('custID', this.$route.query.custID)
        sessionStorage.setItem('custID', custID);
        // console.log('custID3', custID)
        this.$router.push({ name: 'Login', query: { custID } });
      } else if (xml && key) {
        const url = window.location.href;
        const newUrl = url.split('?')[0] + 'login/' + '?' + url.split('?')[1];
        console.log(newUrl);
        window.location.href = newUrl;
      } else if (phone && zhonghezhihui) {
        if (phone.indexOf(',') > -1) {
          const phones = phone.split(',');
          // const custID = window.location.href.split('custID=')[1]
          // console.log('custID', this.$route.query.custID)
          // console.log('custID2', custID)
          // sessionStorage.setItem('custID', JSON.stringify(this.$route.query.custID))
          sessionStorage.setItem('custID', custID);
          this.phoneShow = true;
          this.phones = phones.map((value) => {
            return { name: value };
          });
        } else {
          this.phone = phone;
          getOrg({ phone });
        }
      } else if (weixin && !code && appId) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${this.redirectUrl}?weixin=2023&response_type=code&scope=snsapi_base&state=STATE`;
      } else if (weixin && code && appId) {
        weixinGetTokenAPI(appId, code).then((res) => {
          if (res.code === 0) {
            localStorage.setItem('openid', JSON.parse(res.data).openid);
            const wxPhone = localStorage.getItem('phone')
              ? localStorage.getItem('phone')
              : '';
            if (wxPhone) {
              this.phone = wxPhone;
              getOrg({ phone: wxPhone });
            } else {
              this.$router.push({ name: 'Login', query: { action: 'weixin' } });
            }
          }
        });
      } else if (zhonghezhihui && !phone) {
        this.$router.push({
          name: 'Login',
          query: { action: 'zhonghezhihui' }
        });
      } else if (weixinPayment) {
        const wxPhone = localStorage.getItem('phone');
        this.phone = wxPhone;
        getOrg({ phone: wxPhone });
      } else {
        afterLogin(false);
      }
    } else {
      queryEmployee();
      // fetchBanners()
      if (JSON.parse(sessionStorage.getItem('banners'))) {
        this.banners = JSON.parse(sessionStorage.getItem('banners'));
      } else {
        fetchBanners();
      }
    }

    if (!sessionStorage.getItem('showDialog')) {
      this.showDialog = true;
    }
    // this.getInfo()
    // this.phone = JSON.parse(sessionStorage.getItem('personInfo'))?.phone;
    // if (JSON.parse(sessionStorage.getItem('personInfo')).phone == window.location.href.split('=')[1]) {
    //   this.phone = JSON.parse(sessionStorage.getItem('personInfo')).phone
    // } else {
    //   this.phone = window.location.href.split('=')[1]
    // }

    this.phone = phone;
  },
  methods: {
    // 关闭遮罩层
    hideDialog() {
      this.showDialog = false;
      sessionStorage.setItem('showDialog', true);
    },
    // sleep(numberMillis) {
    //   var now = new Date()
    //   var exitTime = now.getTime() + numberMillis
    //   while (true) {
    //     now = new Date()
    //     if (now.getTime() > exitTime) return
    //   }
    // },
    async getInfo() {
      const res = await getAccInfo();
      // console.log(res)
      if (res.code === 0) {
        this.infoData = res.data;

        sessionStorage.setItem('personInfo', JSON.stringify(this.infoData));

        this.blockedDialog = true;
      }
    },
    async toInfo() {
      const res = await getAccInfo();
      // this.sleep(2000)
      // console.log(res)
      if (res.code === 0) {
        this.$nextTick(() => {
          this.infoData = res.data;
          // this.picUrl = res.data.photo
          sessionStorage.setItem('personInfo', JSON.stringify(this.infoData));
          // this.infoUrl = `${this.apiUrl}/image?name=${this.picUrl}`
        });
        this.$router.push('/personInfo');
      } else {
        // console.log(111)
      }
    },

    async toAccUnbundling() {
      const res = await getAccInfo();
      console.log(res);
      if (res.code === 0) {
        this.$nextTick(() => {
          this.infoData = res.data;
          sessionStorage.setItem('personInfo', JSON.stringify(this.infoData));
        });
        this.$router.push('/accUnbundling');
      } else {
        // console.log(111)
      }
    },

    handler() {
      if (this.fileList.length !== 1) {
        this.$refs.uploader.chooseFile();
      } else {
        this.fileList = [];
        this.$nextTick(() => this.handler());
      }
    },
    handlerImage() {
      if (this.fileList.length !== 1) {
        this.$refs.uploaderImage.chooseFile();
      } else {
        this.fileList = [];
        this.$nextTick(() => this.handlerImage());
      }
    },
    handlerAlbum() {
      if (this.fileList.length !== 1) {
        this.$refs.uploaderAlbum.chooseFile();
      } else {
        this.fileList = [];
        this.$nextTick(() => this.handlerAlbum());
      }
    },
    payFor() {
      const { tuitionPayUrl } = this;
      const { userId } = this.$store.getters;
      let clientType = 22;
      if (ICBCUtil.isIPhone()) {
        clientType = 21;
      }
      console.log(tuitionPayUrl);
      window.location.href = `${tuitionPayUrl}?userId=${userId}&clientType=${clientType}`;
    },

    async getOrg() {
      console.log('getOrg');
      const { getUser, afterLogin, phone } = this;
      const { code, data } = await getOrgByMobile({ phone });
      if (code === 0) {
        console.log(data);
        if (!data) {
          afterLogin(false);
          Toast({
            message: '登录的手机号无效!'
          });
        } else {
          this.orgs = data.map((item) => {
            return { ...item, name: item.zhName };
          });
          if (this.orgs.length === 0) {
            afterLogin(false);
            Toast({
              message: '登录的手机号无效!'
            });
          } else if (this.orgs.length === 1) {
            this.organizationId = data[0].id;
            getUser();
          } else {
            this.orgShow = true;
          }
        }
      } else {
        afterLogin(false);
        Toast({
          message: '登录的手机号无效!'
        });
      }
    },
    async getUser() {
      const {
        $store,
        queryEmployee,
        // fetchBanners,
        afterLogin,
        phone,
        organizationId
      } = this;
      // console.log(organizationId)
      const res = await getUserList({ organizationId, phone });
      const { code, data } = res;
      console.log(data);
      if (code === 0) {
        this.users = data;
        if (data.length === 0) {
          afterLogin(false);
          Toast({
            message: '登录的手机号无效!'
          });
        } else if (data.length === 1) {
          const [{ id: userId }] = data;
          $store
            .dispatch('login/login', { userId })
            .then(() => {
              queryEmployee();
              // fetchBanners();
            })
            .catch((err) => {
              afterLogin(false);
              console.log(err);
            });
        } else {
          this.userShow = true;
        }
      } else {
        afterLogin(false);
        Toast({
          message: '登录的手机号无效!'
        });
      }
    },
    handlePhoneSelect(item) {
      console.log(item);
      const { getOrg } = this;
      this.phoneShow = false;
      this.phone = item.name;
      getOrg();
    },
    afterLogin(logined) {
      console.log(logined);
      this.logined = logined;
    },
    async fetchBanners() {
      const res = await queryBanners();
      const { code, data } = res;
      if (code === 0) {
        if (isArray(data) && !isEmpty(data)) {
          if (!data[0].path || data[0].path === '') {
            data.map((item) => {
              this.banners.push({
                path: '',
                url: item
              });
            });
          } else {
            this.banners = res.data;
          }
          sessionStorage.setItem('banners', JSON.stringify([...this.banners]));
        } else {
          this.banners.push({
            path: '',
            url: banner3
          });
        }
        // this.showBannerList = [
        //   "南充工行西充政协食堂",
        //   "南充工行顺庆机关食堂-二食堂",
        //   "南充工行顺庆机关食堂-一食堂",
        //   "南部县医疗保障局",
        //   "营山政务中心",
        //   "蓬安抚琴餐厅",
        //   "蓬安凤与凰餐厅",
        // ];
        // if (this.showBannerList.includes(this.user.organization.zhName)) {
        //   this.banners = this.newBanners;
        // } else {
        //   console.log("不包含");
        // }
      }
    },
    handleOrgSelect(item) {
      const { getUser } = this;
      this.orgShow = false;
      this.organizationId = item.id;
      getUser();
    },
    handleUserSelect(item) {
      console.log(111111111, item);
      const { $store, queryEmployee, afterLogin } = this;
      this.userShow = false;
      $store
        .dispatch('login/login', { userId: item.id })
        .then(() => {
          queryEmployee();
          // fetchBanners();
          this.blocked = item.blocked;
          this.blockedDialog = true;
        })
        .catch((err) => {
          afterLogin(false);
          console.log(err);
        });
    },
    queryEmployee() {
      this.loading = true;
      this.$store
        .dispatch('user/getInfo')
        .then((data) => {
          console.log('data', data);
          this.user = data;
          this.fileImg = data.photo;
          this.functionalServices = data.organization.functionalServices
            ? JSON.parse(data.organization.functionalServices)
            : [];
          console.log('functionalServices', this.functionalServices);
          this.loadImg = true;
          this.loading = false;
          this.blocked = data.blocked;
          this.blockedDialog = true;
          this.getUserConfigFn();
          this.getSystemSettingFn();
          this.showFaceCheckFn();
          this.fetchBanners();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async toPage(name) {
      if (name === 'MyReportMeal') {
        const res = await getReportMealConfig();
        if (!res.data || res.data.open === 0) {
          this.$toast('未开启此功能，若需使用请联系管理员');
          return;
        }
      }
      console.log(name);
      this.$router.push({ name });
    },
    onOversize() {
      Toast.fail('照片超出大小，请控制在50M');
    },
    async onLoadCard(file) {
      file.status = 'uploading';
      const { queryEmployee, user } = this;
      Toast.loading({
        forbidClick: true,
        duration: 0
      });
      let res;
      try {
        res = await onloadImage({
          fileName: file.file.name,
          image: file.content.split(',')[1]
        });
      } catch (error) {
        Toast.clear();
        console.log(error);
        file.status = 'failed';
        Toast.fail('上传失败');
        this.uploading = false;
        return;
      }

      const { data } = res;
      let response = {};
      try {
        response = await updateEmployeePhoto({ photo: data.name });
      } catch (e) {
        Toast.clear();
        console.log(e);
        file.status = 'failed';
        Toast.fail('上传失败');
        this.uploading = false;
        return;
      }
      Toast.clear();
      const { code } = response;
      if (code === 0) {
        this.loadImg = true;
        file.status = 'success';
        Toast.success('上传成功');
        queryEmployee(user.id);
      } else {
        file.status = 'failed';
      }
      this.uploading = false;
    },
    onRemove() {
      this.fileImg = '';
    },
    // 点击解除绑定
    async unboundBtn() {
      console.log('解除绑定');
      // const id = sessionStorage.getItem('custID')
      // console.log(id)
      const res = await getUnbound({
        id: sessionStorage.getItem('custID')
      });
      if (res.code === 0) {
        // Toast.success('解除绑定成功！')
        // const times = 2
        // if (!this.sTimer) {
        //   this.sCount = times
        //   this.sTimer = setInterval(() => {
        //     if (this.sCount > 0 && this.sCount <= times) {
        //       this.sCount--
        //     } else {
        //       this.dialog = true
        //       clearInterval(this.sTimer)
        //       this.sTimer = null
        //     }
        //   }, 1000)
        // }
        this.dialog = true;
      }
    },
    hideDialogBtn() {
      this.dialog = false;
    },
    // 顶部搜索
    onSearch(e) {
      if (e.keyCode === 13) {
        this.appLocationList.map((item) => {
          item.list.map((citem) => {
            if (citem.title === e.target.value.trim()) {
              this.$router.push({ name: citem.func });
            }
          });
        });
      }
    },
    displayAppListFn() {
      this.appLocationList = [];
      const user = JSON.parse(sessionStorage.getItem('personInfo'));
      const isClassManager = user.isClassManager;
      const classManager = user.classManager;
      const role = user.role;
      const departmentManager = user.departmentManager;
      const isManager = user.isManager;
      if (JSON.parse(user.organization.functionalServices).includes(1)) {
        this.appLocationList.push({
          title: '智慧食堂',
          list: [
            { img: allowance2, title: '补助金', func: 'Allowance' },
            { img: eat2, title: '我的订餐', func: 'Order' },
            { img: detail, title: '消费明细', func: 'Consumption' },
            {
              img: recharge_history,
              title: '账单',
              func: 'RechargeHistory'
            },
            { img: paycode, title: '消费码', func: 'Paycode' },
            { img: recharge, title: '充 值', func: 'Recharge' }
          ]
        });
        if (role === 0) {
          this.appLocationList.forEach((item) => {
            if (item.title === '智慧食堂') {
              item.list.push({
                img: reportMeal,
                title: '我的报餐',
                func: 'MyReportMeal'
              });
            }
          });
        }
        this.actions.push(
          { text: '消费码', icon: paycode, name: 'Paycode' },
          { text: '充 值', icon: recharge, name: 'Recharge' }
        );
        // this.setting.push({
        //   img: pwd,
        //   title: "消费密码设置",
        //   func: "PwdSetting",
        // });
      }
      if (JSON.parse(user.organization.functionalServices).includes(2)) {
        this.appLocationList.push({
          title: '智慧门禁',
          list: [
            { img: check, title: '考勤管理', func: 'CheckWork' },
            { img: inform, title: '通知设置', func: 'SmsAuth' },
            { img: Leave, title: '出入记录', func: 'AccessRecord' }
          ]
        });
        if (role === 1) {
          if (classManager) {
            this.appLocationList.map((item) => {
              if (item.title === '智慧门禁') {
                item.list.push(
                  {
                    img: leave3,
                    title: '请假',
                    func: 'Leave'
                  },
                  {
                    img: u286,
                    title: '接送记录',
                    func: 'PickRecords'
                  }
                );
              }
            });
          }
        } else {
          if (departmentManager) {
            this.appLocationList.map((item) => {
              if (item.title === '智慧门禁') {
                item.list.push({
                  img: leave3,
                  title: '请假',
                  func: 'Leave'
                });
              }
            });
          }
        }
        if (isClassManager || isManager) {
          this.appLocationList.forEach((item) => {
            if (item.title === '智慧门禁') {
              item.list.push({
                img: checkWork,
                title: '审批',
                func: 'ExamineLeave'
              });
              item.list.push({
                img: faceReview,
                title: '人脸审批',
                func: 'FaceCheck'
              });
            }
          });
        }
        if (isClassManager) {
          this.appLocationList.forEach((item) => {
            if (item.title === '智慧门禁') {
              item.list.push({
                img: u287,
                title: '上学管理',
                func: 'ClassInform'
              });
            }
          });
        }
      }
      // if (JSON.parse(user.organization.functionalServices).includes(7)) {
      //   this.appLocationList.push({
      //     title: "智慧停车",
      //     list: [
      //       { img: vip, title: "会员办理", func: "VehicleVip" },
      //       { img: plate, title: "车牌录入", func: "LicensePlate" },
      //       { img: park, title: "停车记录", func: "ParkingRecord" },
      //     ],
      //   });
      //   this.setting.push({
      //     img: vehicle,
      //     title: "我的车辆",
      //     func: "MyVehicle",
      //   });
      // }
      // if (JSON.parse(user.organization.functionalServices).includes(4)) {
      //   this.appLocationList.push({
      //     title: "智慧水电",
      //     list: [
      //       { img: payment, title: "水电费", func: "Payment" },
      //       {
      //         img: paymentConsumption,
      //         title: "水电用量",
      //         func: "PaymentConsumption",
      //       },
      //       { img: paymentList, title: "账单", func: "PaymentList" },
      //     ],
      //   });
      // }
      // if (JSON.parse(user.organization.functionalServices).includes(6)) {
      //   this.appLocationList.push({
      //     title: "智慧办公",
      //     list: [{ img: conference, title: "会议室预约", func: "Conference" }],
      //   });
      // }
      // if (JSON.parse(user.organization.functionalServices).includes(5)) {
      //   this.appLocationList.push({
      //     title: "政企生活",
      //     list: [
      //       {
      //         img: icon_inform,
      //         title: "消息通知",
      //         func: "InformList",
      //       },
      //     ],
      //   });
      //   this.skipDisable = false;
      // }
      // if (JSON.parse(user.organization.functionalServices).includes(8)) {
      //   this.skipDisable = false;
      //   if (isClassManager || classManager) {
      //     this.appLocationList.push({
      //       title: "校园生活",
      //       list: [
      //         { img: classCircle, title: "班级圈", func: "ClassCircle" },
      //         {
      //           img: icon_inform,
      //           title: "消息通知",
      //           func: "InformList",
      //         },
      //       ],
      //     });
      //     if (classManager) {
      //       this.appLocationList.forEach((item) => {
      //         if (item.title === "校园生活") {
      //           item.list.push({
      //             img: reportCard,
      //             title: "成绩单",
      //             func: "PerformanceQuery",
      //           });
      //         }
      //       });
      //     }
      //   }
      // }
    },
    getUserConfigFn() {
      this.displayAppListFn();
      const user = JSON.parse(sessionStorage.getItem('personInfo'));
      getUserConfigAPI(user.id).then((res) => {
        if (res.code === 0) {
          if (res.data) {
            this.homeApp = res.data;
            this.homeApp.config = JSON.parse(this.homeApp.config);
          } else {
            if (JSON.parse(user.organization.functionalServices).includes(2) && JSON.parse(user.organization.functionalServices).includes(1)) {
              const arr = [];
              this.homeApp = { config: [] };
              this.appLocationList.map((item) => {
                item.list.map((citem) => {
                  arr.push(citem);
                });
              });
              const arrDefault = []
              this.appLocationList.map((item) => {
                item.list.map((citem) => {
                  if(citem.title === '补助金' || citem.title === '消费明细' || citem.title === '账单'|| citem.title === '消费码'|| citem.title === '充 值'|| citem.title === '通知设置'|| citem.title === '出入记录'){
                    arrDefault.push(citem);
                  }
                });
              });
              arrDefault.map((item, index) => {
                if (index <= 6) {
                  this.homeApp.config.push(item);
                }
              });
            } else {
              const arr = [];
              this.homeApp = { config: [] };
              this.appLocationList.map((item) => {
                item.list.map((citem) => {
                  arr.push(citem);
                });
              });
              arr.map((item, index) => {
                if (index <= 6) {
                  this.homeApp.config.push(item);
                }
              });
              this.$set(this.homeApp, 'organizationId', user.organizationId);
              this.$set(this.homeApp, 'userId', user.id);
            }
          }
          this.appLocationList.map((item) => {
            item.list.map((citem) => {
              this.titleList.push(citem.title);
              this.pathList.push(citem.func);
            });
          });
          let newArr = [];
          this.homeApp.config.map((item, index) => {
            if (
              !this.titleList.includes(item.title) ||
              !this.pathList.includes(item.func)
            ) {
              newArr.push(index);
            }
          });
          newArr = newArr.sort((a, b) => {
            return b - a;
          });
          newArr.map((item) => {
            this.homeApp.config.splice(item, 1);
          });
          let num = 0;
          this.appLocationList.map((item) => {
            item.list.map((citem) => {
              num += 1;
            });
          });
          if (this.homeApp.config.length < num) {
            this.homeApp.config.push({
              img: all,
              title: '全部',
              func: 'AppCenter'
            });
          }
          console.log(this.homeApp.config, 'config');
        }
      });
    },
    // getBannersListFn() {
    //   this.banners = []
    //   queryBanners().then((res) => {
    //     if (res.code === 0) {
    //       if (res.data.length === 0) {
    //         this.banners.push(banner)
    //       } else {
    //         this.banners = res.data
    //       }
    //     }
    //   })
    // },
    onSelect(action) {
      this.$router.push(action.name);
    },
    getSystemSettingFn() {
      getSystemSettingAPI().then((res) => {
        if (res.code === 0) {
          if (res.data) {
            if (
              res.data.status === 1 &&
              !res.data.whiteList.includes(this.user.phone)
            ) {
              this.isDialog = true;
              this.uploadTime = res.data.time;
            }
          }
        }
      });
    },
    showFaceCheckFn() {
      const { user } = this;
      const { isClassManager, isManager } = user;
      console.log(isClassManager, isManager);
      if (isClassManager || isManager) {
        this.canteen.push({
          img: approval_t,
          title: '人脸审批',
          func: 'FaceCheck'
        });
      }
      console.log(this.canteen, 'canteen');
    },
    toIcbcPage(url) {
      if (url) {
        window.location.href = url;
      }
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .el-dialog__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

// 弹窗的样式
::v-deep .el-dialog {
  margin: 0 auto 8rem;
  width: 65%;
  height: 9rem;
  border-radius: 0.5rem;
}
::v-deep .el-dialog。 {
  margin: 0 auto 8rem;
  width: 65%;
  height: 9rem;
  border-radius: 0.5rem;
}

::v-deep .el-button {
  box-sizing: border-box;
  width: 55% !important;
  height: 2rem;
  line-height: 0.5rem;
  border-radius: 1.2rem;
}

// 关闭弹窗的叉叉
::v-deep .el-dialog__header {
  display: none;
}
.dialogShow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.container {
  min-height: 100%;
  max-height: 200%;
  background-color: #fff;
  .recharge-dialog {
    box-sizing: border-box;
    padding: 2rem;
    text-align: center;
  }
  .category {
    & > .title {
      background-image: linear-gradient(to bottom, #fdece8, #fef4f4);
      padding: 0.3rem 0 0.3rem 3rem;
      font-size: 0.95rem;
      color: #515151;
    }

    & > .content-top {
      background-color: #fff;
      padding: 1rem 2.5rem;
      line-height: 2.25rem;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .balance {
        color: #7e7e7e;
        font-size: 1.5rem;
      }
      .balance-right {
        position: relative;
      }
      .balance-right:after {
        content: '';
        display: block;
        width: 1px;
        height: 50px;
        position: absolute;
        top: 12px;
        right: 0px;
        background: #7e7e7e;
      }
      .coupon {
        color: #7e7e7e;
        font-size: 1.5rem;
      }

      .normal_btn {
        height: 1.4rem;
        width: 5.5rem;
        -webkit-box-flex: 1;
        font-size: 0.95rem;
        color: white;
        background: #ff4c4c;
        border: 1px #ff4c4c solid;
        -webkit-appearance: none;
        padding: 0 2px;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .normal_btn:active {
        border: 1px #e66565 solid;
        background: #e66565;
      }
    }
  }

  .function-open {
    background-color: #fe4c4f;
    color: #fff;
    font-size: 1.5rem;
    padding: 3rem 3rem;
    text-align: center;
  }

  .top {
    background-color: #fe4c4f;
    padding: 2rem 3rem 2rem;
    //padding: 1rem 2rem 1rem;
    .balance {
      color: #ffffff;
      font-size: 30px;
    }
    .coupon {
      color: #ffffff;
      font-size: 30px;
    }

    & > .message {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .detail-m {
        & > .personal {
          color: #fff;
          font-size: 0.85rem;
          display: flex;
          align-items: center;

          & > .title {
            font-size: 1.5rem;
          }

          img {
            width: 1.3rem;
            height: 1.3rem;
            margin-right: 0.3rem;
          }
        }
      }

      & > .head {
        border-radius: 100%;
        width: 7rem;
        height: 8rem;
        background-color: #dcdcdc;

        img {
          width: 7rem;
          height: 8rem;
          border-radius: 100%;
        }
      }
    }
  }

  .mine {
    padding: 0.5rem 1rem 0.5rem 3rem;
    background-color: #fff;
    border-bottom: 5px solid #f2f2f2;
  }

  .content-bottom {
    padding: 2rem 1.2rem 0;
    & > .items {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .detail {
        margin-bottom: 2rem;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .item-flex {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        img {
          width: 2.5rem;
          margin-bottom: 0.75rem;
        }
      }
    }
    .item {
      .menu {
        .menu-content {
          width: 100%;
          margin: 5px 0;
          box-sizing: border-box;
          padding: 0 5px;
          height: 40px;
          line-height: 40px;
          border-bottom: #ededed 1px solid;
          &:nth-child(1) {
            border-top: #ededed 1px solid;
            margin-top: -20px;
          }
        }
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 10px;
    width: 100vw;
    text-align: center;
    font-size: 0.875rem;
  }

  & > .dialog_content {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-181px, -15px);
    z-index: 101;
    display: block;
    border-radius: 4px;
    border: #666 solid 1px;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.9);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(white),
      to(#f0f0f0)
    );
    width: 360px;
    min-height: 30px;
    color: #333;

    h3 {
      text-align: center;
      font-size: 1.1rem;
      margin: 13px 0 0 0;
    }

    & > .dialog_tip {
      text-indent: 0.8rem;
      margin: 18px 0;
      padding: 2px 0;
      word-break: break-all;
      line-height: 19px;
    }

    & > .confirm {
      text-align: center;
      padding: 15px 0;
      color: #ff7b7b;
      border-top: 1px solid #e5e5e5;
    }
  }
}

.container1 {
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  & > .nav {
    width: 100%;
    position: fixed;
    top: 0;
    display: block;
    text-align: center;
    flex: 0 0 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }
  }

  & .back-button {
    background-color: #a2dacf;
    padding: 0.4em 1.8em;
    font-size: 1.2rem;
  }

  .block1 {
    position: relative;
    top: -1.5em;
  }

  & > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8rem;
    flex-grow: 1;
    overflow: hidden;
    background-color: #f6f6f6;

    .finish-img {
      border-radius: 100%;
    }

    & > .finish-text {
      margin-top: 0.5em;
    }

    & > .footer {
      position: fixed;
      bottom: 10px;
      width: 100vw;
      font-size: 0.875rem;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      white-space: nowrap;

      & > div {
        width: 150px;
        text-align: justify;
      }
    }
  }
}

// 冻结账户弹窗
.blockedDialog {
  width: 100%;
  height: 100vh;
  padding-bottom: 5rem;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .blocked-content {
    width: 80%;
    height: 5rem;
    line-height: 5rem;
    border-radius: 5px;
    background: #fff;
  }
}

// 冻结cover
.cover {
  height: 90vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  // align-items: center;
  padding-top: 40vh;
  .cover-content {
    width: 80%;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    border-radius: 5px;
    // background: #fff;
    color: #fff;
    font-weight: bold;
  }
}

.search {
  height: 11rem;
  width: 100%;
  background: url('../../assets/bkg.png') no-repeat;
  background-size: 100% 100%;
  .search_top {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
    form {
      width: 100%;
      .van-search {
        width: 100%;
        text-align: right;
        padding-left: 2rem;
      }
    }

    .van-search__content--round {
      height: 1.7rem !important;
    }
    .inpu_height {
      height: 20px;
    }
    .van-icon {
      width: 10%;
      font-size: 2rem;
      text-align: right;
      margin-top: 0.5rem;
    }
  }
}
.banner_top {
  .van-swipe {
    width: 70%;
    border-radius: 0.5rem;
    overflow: hidden;
    margin: 0 auto;
    .van-swipe-item {
      height: 7rem;
      width: 100%;
      margin: 0 auto;
      img {
        width: 100%;
        height: 7rem;
      }
    }
  }
}
.infrom {
  height: 4rem;
  width: 95%;
  margin: 0 auto;
  border: 1px solid rgba(248, 248, 248);
  display: flex;
  align-items: center;
  .infrom_left {
    text-indent: 0.5rem;
    width: 25%;
    height: 3rem;
    background: rgba(248, 248, 248);
    border-radius: 2rem;
    display: flex;

    align-items: center;
    .infrom_left_img {
      height: 3rem;
      line-height: 3rem;
      img {
        vertical-align: middle;
        height: 2rem;
      }
    }
  }
  .infrom_swipe {
    height: 3rem;
    text-indent: 1rem;
    line-height: 3rem;
  }
}
.swipe_bottom {
  width: 90%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
  img {
    width: 100%;
  }
  p {
    position: absolute;
    bottom: 1rem;
    left: 30%;
    font-size: 1.2rem;
    color: black;
    font-weight: 900;
  }
}

::v-deep .init.van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 4000;
}

::v-deep .c-swipe-pagination-item.active {
  background-color: red;
}
::v-deep .van-dialog__header {
  padding: 0 !important;
  color: black;
  font-weight: 900;
}
.dialog_div {
  height: 100px;
  width: 100%;
  text-align: center;
  line-height: 100px;
  font-size: 14px;
  color: rgba(63, 63, 63);
}
</style>
<style>
page {
  background-color: #fff;
}
</style>
